import { Controller } from "stimulus";

export default class ChartSelectController extends Controller {
  static targets = ["group", "chart"];

  static hiddenChartClass = "analysis-card__chart--hidden";
  static hiddenGroupSelectClass = "analysis-card__chart-group-select--hidden";

  changeVisibleChart(event) {
    this.changeVisibleChartById(event.target.value);
  }

  changeVisibleChartById(id) {
    this.changeVisibleElementById(id, this.chartTargets, ChartSelectController.hiddenChartClass);
    window.ReactRailsUJS.mountComponents("#" + id);
  }

  changeVisibleElementById(id, candidates, hiddenElementClass) {
    const visibleElement = candidates.find((content) => !content.classList.contains(hiddenElementClass));
    const selectedElement = candidates.find((chart) => chart.id === id);

    visibleElement.classList.add(hiddenElementClass);
    selectedElement.classList.remove(hiddenElementClass);
    return selectedElement;
  }

  changeVisibleGroup(event) {
    const visibleGroup = this.changeVisibleElementById(
      event.target.value,
      this.groupTargets,
      ChartSelectController.hiddenGroupSelectClass
    );
    this.changeVisibleChartById(visibleGroup.value);
  }
}
