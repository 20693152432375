import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["footer"];

  initialize() {
    this.close = this.close.bind(this);
  }

  close() {
    this.footerTarget.remove();
  }
}
