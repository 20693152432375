/**
 * @param selector
 *  To hook into a react component using this method, you will need to set a CSS class on it.
 *  See modal_component.html.haml for an example of how to do this.
 * @param newState
 *  An object literal. For best results, only pass primitives for the object values.
 *
 * Limitations:
 * - This method only does a *shallow* merge with the old state.
 * - While the JSON serializing / deserializing is a bit gross, as it turns out it's very similar to what
 *   rails_ujs also does under the covers.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setReactComponentState = (selector: string, newState: { [key: string]: any }) => {
  const element = document.querySelector(selector);

  if (!element) {
    return;
  }

  const stateAttribute = "data-react-props";
  const oldState = JSON.parse(element.getAttribute(stateAttribute));

  const updatedState = { ...oldState, ...newState };

  element.setAttribute(stateAttribute, JSON.stringify(updatedState));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).ReactRailsUJS.mountComponents(selector);
};
