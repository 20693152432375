import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["choices", "tickers"];

  chooseTickers() {
    this.tickersTarget.style.display = "block";
    this.choicesTarget.style.display = "none";
  }
}
