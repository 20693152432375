import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    this.isOpen = false;
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    const modifier = this.isOpen ? "wholesaler-info--open" : "wholesaler-info--closed";
    this.containerTarget.classList = `wholesaler-info ${modifier}`;
  }
}
