import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["holdings", "holdingTemplate", "weight", "weightTotal"];

  initialize() {
    // Add an empty row if there are none
    if (this.holdingsTarget.children.length === 0) {
      this.addHolding();
    }
  }

  removeHolding(e) {
    e.preventDefault();
    e.target.closest(".js-portfolio-holding").remove();
  }

  addHolding(e) {
    e && e.preventDefault();
    const templateClone = document.importNode(this.holdingTemplateTarget.content, true);
    this.holdingsTarget.appendChild(templateClone);
  }

  computeTotalWeight() {
    const totalWeight = this.weightTargets.reduce((total, weightTarget) => {
      return total + parseFloat(weightTarget.value || 0.0);
    }, 0);
    this.weightTotalTarget.innerText = totalWeight.toFixed(1) + "%";
  }
}
