import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add", "choose", "form"];

  addCustomComparison() {
    this.addTarget.classList.remove("custom-benchmark__container--hidden");
    this.chooseTarget.classList.add("custom-benchmark__container--hidden");
  }
}
