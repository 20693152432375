// See https://github.com/rails/webpacker#usage.
// For information on how these imports get transformed by babel, see
// https://babeljs.io/docs/en/babel-preset-env#how-does-it-work, particularly the section related to useBuiltIns
import "core-js/stable";
import "regenerator-runtime/runtime";

// IE Polyfills not handled by babel or core-js
import 'whatwg-fetch' // https://github.com/babel/babel/issues/9160

// Manual Polyfills

/**
 * Element.closest() polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 */
if (!window.Element.prototype.closest) {
  if (!window.Element.prototype.matches) {
    window.Element.prototype.matches = window.Element.prototype.msMatchesSelector || window.Element.prototype.webkitMatchesSelector;
  }
  window.Element.prototype.closest = function (s) {
    var el = this;
    var ancestor = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (ancestor.matches(s)) return ancestor;
      ancestor = ancestor.parentElement;
    } while (ancestor !== null);
    return null;
  };
}
