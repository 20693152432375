import { Controller } from "stimulus";

import { setReactComponentState } from "../javascripts/rails-ujs-helpers";

export default class extends Controller {
  static targets = ["items"];

  initialize() {
    this.openClass = this.element.dataset.openClass;
    this.close = this.close.bind(this);
    this.documentClickHandler = this.documentClickHandler.bind(this);
    this.blockCssOpacityTransitionWhenPageLoads();
  }

  open(e) {
    e.stopPropagation();
    // There's some question whether using setReactComponentState is the right pattern for this case vs.
    // dispatching and listening for custom events.
    setReactComponentState(".user-menu-popover", { forceClose: true });
    this.itemsTarget.classList.add(this.openClass);
    document.body.addEventListener("click", this.documentClickHandler, false);
  }

  close(e) {
    this.itemsTarget.classList.remove(this.openClass);
    document.body.removeEventListener("click", this.documentClickHandler, false);
  }

  documentClickHandler(e) {
    if (!this.itemsTarget.contains(e.target)) {
      this.close(e);
    }
  }

  /**
   * The logic below, while a bit gross, is needed to ensure the hamburger menu is not briefly visible on large
   * screen sizes, due to our opacity transition, when the website first loads. The logic is inspired
   * by https://css-tricks.com/transitions-only-after-page-load/.
   *
   * (Fwiw, the CSS-tricks folks also conceded that the logic is a bit gross. :/)
   */
  blockCssOpacityTransitionWhenPageLoads() {
    // Only apply CSS fix if page hasn't loaded
    if (document.readyState !== "complete") {
      this.itemsTarget.style.display = "none";
      window.addEventListener("load", () => (this.itemsTarget.style.display = "block"));
    }
  }
}
