import { Controller } from "stimulus";

// Ugly hack around the events for autocomplete to update the name colum. Will be simpler in React land
export default class extends Controller {
  static targets = ["source", "name"];

  initialize() {
    this.sourceTarget.addEventListener("autocomplete.change", (e) => {
      const tickerName = e.detail.textValue.replace(`: ${e.detail.value}`, "");
      this.nameTarget.innerHTML = tickerName;
    });
  }
}
