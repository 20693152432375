import { Controller } from "stimulus";

export default class extends Controller {
  STICKY_CLASS = "js-sticky-element";
  ACTIVE_CLASS = `${this.STICKY_CLASS}--active`;
  HIDDEN_CLASS = `${this.STICKY_CLASS}--hidden`;

  initialize() {
    this.stickyElement = this.element.querySelector(`.${this.element.dataset.stickyElement}`);
    this.stickyElement.classList.add(this.STICKY_CLASS);

    // If you derive the navOffset when the page first renders, the layout may not have settled in some cases. We kick
    // setting this value forward somewhat to prevent the case. In local testing, setting this timeout to 100 worked but
    // inconsistently. Doubling the value for safety purposes. See ESS-818.
    setTimeout(() => {
      this.navOffset = this.stickyElement.offsetTop;
    }, 200);

    this.handleScroll = this.handleScroll.bind(this);
  }

  connect() {
    window.addEventListener("scroll", this.handleScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.navOffset) {
      return;
    }

    if (window.pageYOffset >= this.navOffset) {
      this.stickyElement.classList.add(this.ACTIVE_CLASS);
    } else {
      this.stickyElement.classList.remove(this.ACTIVE_CLASS);
    }

    // Note: The container's height may not have settled when the controller is instantiated. So we have to
    // calculate the bottomBound value here.
    const bottomBound = this.element.offsetTop + this.element.offsetHeight - this.stickyElement.offsetHeight * 1.25;

    if (window.pageYOffset >= bottomBound) {
      this.stickyElement.classList.add(this.HIDDEN_CLASS);
    } else {
      this.stickyElement.classList.remove(this.HIDDEN_CLASS);
    }
  }
}
