import { Controller } from "stimulus";

export default class TabsController extends Controller {
  static targets = ["tab", "content"];

  static selectTabClass = "tab--selected";
  static hiddenContentClass = "tab-content--hidden";

  select(event) {
    const selectedTab = event.target;
    const contentId = selectedTab.dataset.tabContent;
    const selectedContent = this.contentTargets.find((content) => content.id === contentId);

    // Swap the selectedTab and styles
    this.selectedTab().classList.remove(TabsController.selectTabClass);
    selectedTab.classList.add(TabsController.selectTabClass);

    // Swap the visibleContent and styles
    this.visibleContent().classList.add(TabsController.hiddenContentClass);
    selectedContent.classList.remove(TabsController.hiddenContentClass);

    // Rewrite the URL so that the tab will persist on page refresh
    window.history.replaceState(window.history.state, "", "?tab=" + selectedTab.dataset.tabId);
  }

  selectedTab() {
    return this.tabTargets.find((tab) => tab.classList.contains(TabsController.selectTabClass));
  }

  visibleContent() {
    return this.contentTargets.find((content) => !content.classList.contains(TabsController.hiddenContentClass));
  }
}
